<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import './assets/js/baidu-popularize'
const locale = computed(() => zhCn)
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}
onMounted(() => {
  // 判断手机还是电脑
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  if (!flag) {
    window.resetRem(20)
    //电脑
  } else {
    //手机
    if (window.innerWidth >= 567) { // 大于567px的移动设备按照电脑端的样式处理
      window.resetRem(20)
    } else {
      window.resetRem(65)
    }
  }
})
</script>

<style lang="scss" scoped>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif; /*定义全局字体*/
    -webkit-font-smoothing: antialiased; /*渲染规则: 字体抗锯齿。适用于 Chrome 和 Safiri 浏览器*/
    -moz-osx-font-smoothing: grayscale; /*渲染规则: 字体抗锯齿。火狐针对 ios 系统制定的规则*/
    text-align: center; /*定义全局字体居中*/
    color: #2c3e50; /*定义全局字体颜色*/
  }
</style>

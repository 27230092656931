import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  // 悟空图像
  {
    path: '/',
    name: 'layoutView',
    component: () => import('@/views/layoutView'),
    redirect: '/PhotoSir',
    children: [
      /*------------系列产品-------------*/
      // 悟空图像
      {
        path: '/PhotoSir',
        name: 'PhotoSir',
        component: () => import('@/views/PhotoSir.vue'),
      },
      // 悟空编程版本
      {
        path: '/Program',
        name: 'Program',
        component: () => import('@/views/ProgrammingVersion.vue'),
      },
      // 悟空ai闪绘
      {
        path: '/AiFlashDrawing',
        name: 'AiFlashDrawing',
        component: () => import('@/views/AiFlashDrawing.vue'),
      },
      // 悟空套版
      {
        path: '/Registering',
        name: 'Registering',
        component: () => import('@/views/photosirRegistering'),
      },
      // 悟空看图
      {
        path: '/LookPicture',
        name: 'LookPicture',
        component: () => import('@/views/LookPicture.vue'),
      },
      // 悟空拼图
      {
        path: '/PhotoSplicing',
        name: 'PhotoSplicing',
        component: () => import('@/views/PhotoSplicing'),
      },
      // 悟空传图
      {
        path: '/PhotoUpload',
        name: 'PhotoUpload',
        component: () => import('@/views/PhotoUpload'),
      },
      /*---------系列产品页面----------*/

      /*---------销售相关页面start---------*/
      // 购买
      {
        path: '/Buy',
        name: 'Buy',
        component: () => import('@/views/photosirBuy'),
      },
      // 企业购买(团购)
      {
        path: '/GroupBuy',
        name: 'GroupBuy',
        component: () => import('@/views/GroupBuy'),
      },
      // 活动&资讯
      {
        path: '/CompanyNews',
        name: 'CompanyNews',
        component: () => import('@/views/CompanyNews'),
      },
      // 活动&资讯-->详情页
      {
        path: '/CompanyNewsDetails',
        name: 'CompanyNewsDetails',
        component: () => import('@/views/CompanyNewsDetails'),
      },
      // 下载
      {
        path: '/Download',
        name: 'Download',
        component: () => import('@/views/DownloadView'),
      },
      // 教程
      {
        path: '/VideoTutorial',
        name: 'VideoTutorial',
        component: () => import('@/views/OnlineTutorial'),
      },
      // 教程详情
      {
        path: '/VideosDetails',
        name: 'VideosDetails',
        component: () => import('@/views/CourseDetails'),
      },
      // 关于我们
      {
        path: '/ContactView',
        name: 'ContactView',
        component: () => import('@/views/ContactView'),
      },
      // 活动中心
      {
        path: '/ActivityCenter',
        name: 'ActivityCenter',
        component: () => import('@/views/ActivityCenter'),
      },
      // 活动中心
      {
        path: '/ActivityDetails',
        name: 'ActivityDetails',
        component: () => import('@/views/ActivityDetails'),
      }
    ]
  },
  /*------------------销售相关页面------------------ */
  // 联系
  {
    path: '/ContacTouter',
    name: 'ContacTouter',
    component: () => import('@/views/ContacTouter'),
  },
  // vip购买pc
  {
    path: '/VipPc',
    name: 'VipPc',
    component: () => import('../views/sales/ps_buy.vue'),
  },
  // 素材包购买pc
  {
    path: '/PackagePc',
    name: 'PackagePc',
    component: () => import('@/views/sales/collaborativeDelisting.vue'),
  },
  // 联想联运 素材包- PC
  {
    path: '/PackageLenovo',
    name: 'PackageLenovo',
    redirect:(to) => {
      return {
          path: '/PackagePc',
          query: {source:1,
              token: to.query.token
          }
      }
    }
  },
  {
    path: '/PackageLenovoProgram',
    name: 'PackageLenovoProgram',
    redirect:(to) => {
      return {
          path: '/PackagePc',
          query: {source:16,
              token: to.query.token
          }
      }
    }
  },
  // 联想联运 素材包- PC
  {
    path: '/PackageLenovopad',
    name: 'PackageLenovopad',
    redirect:(to) => {
      return {
          path: '/PackagePc',
          query: {source:17,
              token: to.query.token
          }
      }
    }
  },
  // 联想联运闪绘 素材包- PC
  {
    path: '/FlashPackageLenovopad',
    name: 'FlashPackageLenovopad',
    redirect:(to) => {
      return {
          path: '/PackagePc',
          query: {source:20,
              token: to.query.token
          }
      }
    }
  },
  // 素材包购买 （手机）
  {
    path: '/package_phone',
    name: 'package_phone',
    component: () => import('@/components/package_phone.vue'),
  },
  // 素材包购买 - PC
  {
    path: '/PackagePc',
    name: 'PackagePc',
    component: () => import('@/views/sales/collaborativeDelisting.vue'),
  },
  {
    path: '/PackagePcpad',
    name: 'PackagePcpad',
    redirect:(to) => {
          return {
              path: '/PackagePc',
              query: {
                token: to.query.tokenpad
            }
          }
    }
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import('../views/sales/salesIndex.vue'),
    children: [
      {
        //会员&次数卡购买(H5)
        path: '/sales/sviph5',
        name: 'sviph5',
        component: () => import('../views/sales/svip_h5.vue'),
      },
      // 会员&次数卡购买(PC)
      {
        path: '/sales/svippc',
        name: 'svippc',
        component: () => import('../views/sales/ps_buy.vue'),
      },
    ],
  },
  /*---------销售相关页面start---------*/
  // 联想联运
  {
    path: '/VipLenovo',
    name: 'VipLenovo',
    redirect:(to) => {
      return {
          path: '/sales/svippc',
          query: {source:1,
              TabId: to.query.TabId,
              token: to.query.token
          }
      }
    }
    //component: () => import('../views/sales/ps_buy.vue'),
  },
  // 联想联运
  {
    path: '/VipLenovoProgram',
    name: 'VipLenovoProgram',
    redirect:(to) => {
      return {
          path: '/sales/svippc',
          query: {source:16,
              TabId: to.query.TabId,
              token: to.query.token
          }
      }
    }
    //component: () => import('../views/sales/ps_buy.vue'),
  },
  // 联想联运
  {
    path: '/VipLenovopad',
    name: 'VipLenovopad',
    redirect:(to) => {
      return {
          path: '/sales/svippc',
          query: {source:17,
              TabId: to.query.TabId,
              token: to.query.token
          }
      }
    }
  },
  {
    path: '/FlashLenovopad',
    name: 'FlashLenovopad',
    redirect:(to) => {
      return {
          path: '/sales/svippc',
          query: {source:20,
              TabId: to.query.TabId,
              token: to.query.token
          }
      }
    }
  },
  /*---------联想创意大赛start---------*/
  // 首页
  {
    path: '/LenovoCompetition',
    name: 'LenovoCompetition',
    component: () => import('../views/activity/LenovoCompetition'),
  },
  // 作品列表
  {
    path: '/LenovoWorks',
    name: 'LenovoWorks',
    component: () => import('../views/activity/LenovoWorks'),
  },
  // 上传作品
  // {
  //   path: '/LenovoUploadFlex',
  //   name: 'LenovoUploadFlex',
  //   component: () => import('../views/activity/LenovoUploadFlex'),
  // },
  // 上传作品(pc端内嵌网页)
  {
    path: '/LenovoUploadWorks',
    name: 'LenovoUploadWorks',
    component: () => import('../views/activity/LenovoUploadWorks'),
  },
  // 获奖作品列表
  {
    path: '/LenovoAwardList',
    name: 'LenovoAwardList',
    component: () => import('../views/activity/LenovoAwardList'),
  },
  /*---------联想创意大赛end---------*/
  /*---------年度设计大赛---------*/
  {
    path: '/apcHome',
    name: 'apcHome',
    component: () => import('../views/photosirApcActivity/apcHome.vue'),
    redirect: '/apcInfo',
    children: [
      // 首页信息
      {
        path: '/apcInfo',
        name: 'apcInfo',
        component: () => import('../views/photosirApcActivity/apcInfo.vue'),
        meta: {
          title: '年度设计大赛'
        }
      },
      // 作品展示
      {
        path: '/apcEbonfwrs',
        name: 'apcEbonfwrs',
        component: () => import('../views/photosirApcActivity/apcEbonfwrs.vue'),
        meta: {
          title: '年度设计大赛'
        }
      },
       // 获奖作品
       {
        path: '/apcaWardworks',
        name: 'apcaWardworks',
        component: () => import('../views/photosirApcActivity/apcaWardworks.vue'),
        meta: {
          title: '年度设计大赛'
        }
      }
    ]
  },
  /*---------ai绘画大赛---------*/
 // 获奖作品
   {
    path: '/AiUpload',
    name: 'AiUpload',
    component: () => import('../views/photosiAIActivity/AiUpload.vue'),
    meta: {
      title: 'ai绘画大赛'
    }
  },
  {
    path: '/AiHome',
    name: 'AiHome',
    component: () => import('../views/photosiAIActivity/AiHome.vue'),
    redirect: '/AiInfo',
    children: [
      // 首页信息
      {
        path: '/AiInfo',
        name: 'AiInfo',
        component: () => import('../views/photosiAIActivity/AiInfo.vue'),
        meta: {
          title: 'ai绘画大赛'
        }
      },
      // 作品展示
      {
        path: '/AiEbonfwrs',
        name: 'AiEbonfwrs',
        component: () => import('../views/photosiAIActivity/AiEbonfwrs.vue'),
        meta: {
          title: 'ai绘画大赛'
        }
      },
       // 获奖作品
       {
        path: '/AiaWardworks',
        name: 'AiaWardworks',
        component: () => import('../views/photosiAIActivity/AiaWardworks.vue'),
        meta: {
          title: 'ai绘画大赛'
        }
      },
    ]
  },
  /***************** 微信广告物料页面   *******************/
  {
    path: '/wxAdvertisingMaterials',
    name: 'wxAdvertisingMaterials',
    component: () => import('@/views/wxAdvertisingMaterials/wxAdvertisingMaterials.vue'),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
